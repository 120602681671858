import React from "react"
import EventLayout from "../../components/layouts/eventLayout"

const LeadershipEvansIndexPage = props => (
  <EventLayout pageTitle="Leadership Evans">
    <p>Information regarding 2024 program coming soon.</p>
    {/**
    <p>
      Leadership Evans is a comprehensive adult leadership development program of the Claxton-Evans County Chamber of Commerce, 
      designed to develop, train, motivate, and recognize existing and emerging leaders in Evans County.  
    </p><br />
    <p>
      Participation is open to adults, age 18 and over, who have demonstrated leadership potential, an interest in the community, 
      and a willingness to fully participate in the Leadership Evans program.  
    </p><br />
    <p>
      The Leadership Evans program is held biennially during even numbered years.  The program historically begins in January and 
      concludes with a graduation ceremony in May.  Each class session exposes the Leadership Evans participants to various aspects of 
      Evans County and covers a wide array of topics including local government, history, understanding community socio-demographics, 
      economic development, and leading change.  The program also includes a mandatory retreat and field trips/tours.  
    </p><br />
    <p>
      The life of Evans County depends upon the actions of citizens who respond to the call for leadership.  Leadership training 
      is an important component of progressive community life.  Leadership Evans wants to be the springboard for emerging leaders 
      in our community.
    </p><br /><br /><br />
    <h3 css={{marginBottom: `1rem`}}>Nomination & Selection Criteria</h3>
    <p>
      Nominations for the 2023 Leadership Evans program are now being accepted at the Chamber of Commerce.  Nominations can be submitted 
      via telephone or in writing.  Deadline for nominations is November 1.  Contact the Chamber, 739-1391 or {" "}
      <a href="mailto:thall@claxtonevanschamber.com">thall@claxtonevanschamber.com</a> with nominee’s name and contact information.
    </p><br />
    <p>
      Nominees will receive a Leadership Evans information packet which includes an application, program schedule, and Leadership 
      Evans application agreement.   Participants will be chosen by a Selection Committee and are subject to the approval of the Chamber 
      Board of Directors and the Leadership Evans Alumni Association.  The Selection Committee seeks representation from a cross-section 
      of the community.  
    </p><br />
    <p>
      Successful candidates will be notified by mid-November.
    </p><br />
    <p>  
      For more information on the Leadership Evans program, please contact Tammi R. Hall at the Claxton-Evans County Chamber of Commerce, 
      739-1391, or email <a href="mailto:thall@claxtonevanschamber.com">thall@claxtonevanschamber.com</a> . 
    </p>
*/}
  </EventLayout>
)

export default LeadershipEvansIndexPage
